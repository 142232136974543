.referralAndPromo {
    display: flex;
    flex-direction: column;
    width: 50%;

    .promoApplied {
        display: flex;
        align-items: center;
        gap: 0.5rem;

        button {
            padding: 5px 15px;
            font-size: 0.75rem;
            height: 35px;
        }

        p {
            margin: 0;
        }
    }
}
